import './bootstrap'; // This line imports Bootstrap 4, if it exists in your project

import 'bootstrap'; // This line imports Bootstrap 5 JavaScript

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

require('./calendar');

require('canvasjs/dist/canvasjs.3.js');

require('datatables');